<template>
<v-card>
  <v-row no-gutters class="mx-2">
    <v-col cols="12" class="mt-6">

    </v-col>
  </v-row>
</v-card>
</template>

<script>
export default {
  name: "ComponentTraceabilityTab",

  components: {
  },

  props: {
    component: { type: Object, default: () => null },
  },

  data() {
    return {

    }
  },

  methods: {
    getTraceability() {
      // TODO
    }
  }
};
</script>

<style scoped>

</style>
